import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anulado'
})
export class AnuladoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value){
      return 'Si';
    }else{
      return 'No';
    }

  }

}
