import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../../../pages/services/global.service';
import { VERSION } from '../../../../environments/version';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  namespace:string;
  // la version de la aplicacion en npm
  npm_version = VERSION.version;
  // la version de la aplicacione en git
  git_version = VERSION.tag;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme;

  userMenu = [
     { title: 'Cerrar sesion' },
     { title: "Version "+this.git_version}  
    ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              public auth0Service: AuthService,
              public globalService:GlobalService,
              @Inject(NB_WINDOW) private window
              ) {
                this.namespace=environment.namespace;
  }

  ngOnInit() {
    // this.currentTheme = this.themeService.currentTheme;
    let data = JSON.parse(localStorage.getItem("CONF"));
    if(data != null){
      this.currentTheme = data.tema?.nombre ?? 'dark';
      this.themeService.changeTheme(this.currentTheme);
    }else{
      this.currentTheme = 'dark';
      this.themeService.changeTheme(this.currentTheme);
    }
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      this.auth0Service.isLoading$.subscribe(                                                                                                                                                                                                                                                                                                                                                                                                                               
        (data: any) => {
          if (!data) {
            this.auth0Service.isAuthenticated$.subscribe(
              (data: any) => {
                if (!data) {
                  console.warn("usuario no autenticado");
                } else {
                  console.log("usuario autenticado");
                  this.auth0Service.user$.subscribe(
                    data=>{
                      // console.log(data);
                      this.globalService.setUsuario(data);
                    });
                }
              },
              (err) => console.error(err)
            );
          }
        },
        (err) => console.error(err)
      );

      this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title =>{ 
        console.log(title);
        if(title=="Cerrar sesion"){
          this.auth0Service.logout({ returnTo: document.location.origin });
        }
        // this.window.alert(`${title} was clicked!`);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  login(){
    this.auth0Service.loginWithRedirect(
      {ui_locales: 'es',allowSignUp: false}
      ).subscribe(
      data=>{
        console.log(data);
      },error=>{
        console.error(error);
      }
    );
  }

  getRol(profile:any){
    return profile[`${this.namespace}/roles`][0];
  }

  verificarRol(profile){
    if(profile[`${this.namespace}/roles`][0] == undefined){
       return false;
    }else{
      return true;
    }
  }
}
