<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="assets/images/logotipo.jpeg" alt="" srcset=""></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <ng-template #nologeado>
      <a class="ui item" 
        (click)="login()" 
        >
        Iniciar Sesión
      </a>
    </ng-template>
    <nb-action *ngIf="auth0Service.isAuthenticated$ | async;else nologeado">    
      <nb-action *ngIf="auth0Service.user$ | async as profile " class="user-action">
        <nb-user *ngIf="verificarRol(profile);else sin_rol" 
                [nbContextMenu]="userMenu"
                [onlyPicture]="userPictureOnly"
                [name]="profile.name"
                [picture]="profile.picture"
                [title]="getRol(profile)"
                nbContextMenuTag="my-context-menu">
        </nb-user>
        <ng-template #sin_rol>
          <nb-user  
          [nbContextMenu]="userMenu"
          [onlyPicture]="userPictureOnly"
          [name]="profile.name"
          [picture]="profile.picture"
          [title]="'sin rol'"
          nbContextMenuTag="my-context-menu">
        </nb-user>
        </ng-template>
      </nb-action>
    </nb-action>
  </nb-actions>

</div>


