import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  usuario:any={};
  impresoraSeleccionada:any={};
  constructor(private toastrService: NbToastrService) { }

  setUsuario(data){
    this.usuario=data;
  }

  getUsuario(){
    return this.usuario;
  }

  setImpresoraSeleccionada(impresora:string){
    this.impresoraSeleccionada={nombre:impresora}
     }

  getImpresoraSeleccionada(){
    let data = JSON.parse(localStorage.getItem("CONF"));
    if(data != null){
      this.impresoraSeleccionada = data.impresora?.nombre;
      return this.impresoraSeleccionada;
    }
    return undefined;
  }

  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 5000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
  
    this.toastrService.show(
      body,
      `${title}`,
      config);
  }
}
